*, :before, :after {
  box-sizing: border-box;
  border: 0 solid #e5e7eb;
}

:before, :after {
  --tw-content: "";
}

html {
  -webkit-text-size-adjust: 100%;
  tab-size: 4;
  font-feature-settings: normal;
  font-family: ui-sans-serif, system-ui, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Helvetica Neue, Arial, Noto Sans, sans-serif, Apple Color Emoji, Segoe UI Emoji, Segoe UI Symbol, Noto Color Emoji;
  line-height: 1.5;
}

body {
  line-height: inherit;
  margin: 0;
}

hr {
  height: 0;
  color: inherit;
  border-top-width: 1px;
}

abbr:where([title]) {
  text-decoration: underline dotted;
}

h1, h2, h3, h4, h5, h6 {
  font-size: inherit;
  font-weight: inherit;
}

a {
  color: inherit;
  text-decoration: inherit;
}

b, strong {
  font-weight: bolder;
}

code, kbd, samp, pre {
  font-family: ui-monospace, SFMono-Regular, Menlo, Monaco, Consolas, Liberation Mono, Courier New, monospace;
  font-size: 1em;
}

small {
  font-size: 80%;
}

sub, sup {
  vertical-align: baseline;
  font-size: 75%;
  line-height: 0;
  position: relative;
}

sub {
  bottom: -.25em;
}

sup {
  top: -.5em;
}

table {
  text-indent: 0;
  border-color: inherit;
  border-collapse: collapse;
}

button, input, optgroup, select, textarea {
  font-family: inherit;
  font-size: 100%;
  font-weight: inherit;
  line-height: inherit;
  color: inherit;
  margin: 0;
  padding: 0;
}

button, select {
  text-transform: none;
}

button, [type="button"], [type="reset"], [type="submit"] {
  -webkit-appearance: button;
  background-color: #0000;
  background-image: none;
}

:-moz-focusring {
  outline: auto;
}

:-moz-ui-invalid {
  box-shadow: none;
}

progress {
  vertical-align: baseline;
}

::-webkit-inner-spin-button, ::-webkit-outer-spin-button {
  height: auto;
}

[type="search"] {
  -webkit-appearance: textfield;
  outline-offset: -2px;
}

::-webkit-search-decoration {
  -webkit-appearance: none;
}

::-webkit-file-upload-button {
  -webkit-appearance: button;
  font: inherit;
}

summary {
  display: list-item;
}

blockquote, dl, dd, h1, h2, h3, h4, h5, h6, hr, figure, p, pre {
  margin: 0;
}

fieldset {
  margin: 0;
  padding: 0;
}

legend {
  padding: 0;
}

ol, ul, menu {
  margin: 0;
  padding: 0;
  list-style: none;
}

textarea {
  resize: vertical;
}

input::placeholder, textarea::placeholder {
  opacity: 1;
  color: #9ca3af;
}

button, [role="button"] {
  cursor: pointer;
}

:disabled {
  cursor: default;
}

img, svg, video, canvas, audio, iframe, embed, object {
  vertical-align: middle;
  display: block;
}

img, video {
  max-width: 100%;
  height: auto;
}

[hidden] {
  display: none;
}

.container {
  width: 100%;
  margin-left: auto;
  margin-right: auto;
  padding-left: 2rem;
  padding-right: 2rem;
}

@media (max-width: 639px) {
  .container {
    padding-left: 1.25rem;
    padding-right: 1.25rem;
  }
}

.container {
  max-width: 68rem;
}

*, :before, :after, ::backdrop {
  --tw-border-spacing-x: 0;
  --tw-border-spacing-y: 0;
  --tw-translate-x: 0;
  --tw-translate-y: 0;
  --tw-rotate: 0;
  --tw-skew-x: 0;
  --tw-skew-y: 0;
  --tw-scale-x: 1;
  --tw-scale-y: 1;
  --tw-pan-x: ;
  --tw-pan-y: ;
  --tw-pinch-zoom: ;
  --tw-scroll-snap-strictness: proximity;
  --tw-ordinal: ;
  --tw-slashed-zero: ;
  --tw-numeric-figure: ;
  --tw-numeric-spacing: ;
  --tw-numeric-fraction: ;
  --tw-ring-inset: ;
  --tw-ring-offset-width: 0px;
  --tw-ring-offset-color: #fff;
  --tw-ring-color: #3b82f680;
  --tw-ring-offset-shadow: 0 0 #0000;
  --tw-ring-shadow: 0 0 #0000;
  --tw-shadow: 0 0 #0000;
  --tw-shadow-colored: 0 0 #0000;
  --tw-blur: ;
  --tw-brightness: ;
  --tw-contrast: ;
  --tw-grayscale: ;
  --tw-hue-rotate: ;
  --tw-invert: ;
  --tw-saturate: ;
  --tw-sepia: ;
  --tw-drop-shadow: ;
  --tw-backdrop-blur: ;
  --tw-backdrop-brightness: ;
  --tw-backdrop-contrast: ;
  --tw-backdrop-grayscale: ;
  --tw-backdrop-hue-rotate: ;
  --tw-backdrop-invert: ;
  --tw-backdrop-opacity: ;
  --tw-backdrop-saturate: ;
  --tw-backdrop-sepia: ;
}

.container {
  width: 100%;
}

@media (min-width: 640px) {
  .container {
    max-width: 640px;
  }
}

@media (min-width: 768px) {
  .container {
    max-width: 768px;
  }
}

@media (min-width: 1024px) {
  .container {
    max-width: 1024px;
  }
}

@media (min-width: 1280px) {
  .container {
    max-width: 1280px;
  }
}

@media (min-width: 1536px) {
  .container {
    max-width: 1536px;
  }
}

.sr-only {
  width: 1px !important;
  height: 1px !important;
  clip: rect(0, 0, 0, 0) !important;
  white-space: nowrap !important;
  border-width: 0 !important;
  margin: -1px !important;
  padding: 0 !important;
  position: absolute !important;
  overflow: hidden !important;
}

.visible {
  visibility: visible !important;
}

.fixed {
  position: fixed !important;
}

.absolute {
  position: absolute !important;
}

.relative {
  position: relative !important;
}

.sticky {
  position: sticky !important;
}

.inset-x-14 {
  left: 3.5rem !important;
  right: 3.5rem !important;
}

.top-0 {
  top: 0 !important;
}

.right-5 {
  right: 1.25rem !important;
}

.right-11 {
  right: 2.75rem !important;
}

.left-12 {
  left: 3rem !important;
}

.-bottom-4 {
  bottom: -1rem !important;
}

.bottom-0 {
  bottom: 0 !important;
}

.left-96 {
  left: 24rem !important;
}

.-bottom-96 {
  bottom: -24rem !important;
}

.right-16 {
  right: 4rem !important;
}

.top-14 {
  top: 3.5rem !important;
}

.z-30 {
  z-index: 30 !important;
}

.z-50 {
  z-index: 50 !important;
}

.z-10 {
  z-index: 10 !important;
}

.mx-1 {
  margin-left: .25rem !important;
  margin-right: .25rem !important;
}

.mr-\[0\.375rem\] {
  margin-right: .375rem !important;
}

.mt-8 {
  margin-top: 2rem !important;
}

.ml-5 {
  margin-left: 1.25rem !important;
}

.flex {
  display: flex !important;
}

.grid {
  display: grid !important;
}

.hidden {
  display: none !important;
}

.h-screen {
  height: 100vh !important;
}

.h-full {
  height: 100% !important;
}

.h-6 {
  height: 1.5rem !important;
}

.w-screen {
  width: 100vw !important;
}

.w-6 {
  width: 1.5rem !important;
}

.w-full {
  width: 100% !important;
}

.w-\[33rem\] {
  width: 33rem !important;
}

.-rotate-12 {
  --tw-rotate: -12deg !important;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y)) !important;
}

.-rotate-1 {
  --tw-rotate: -1deg !important;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y)) !important;
}

.rotate-6 {
  --tw-rotate: 6deg !important;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y)) !important;
}

.rotate-45 {
  --tw-rotate: 45deg !important;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y)) !important;
}

.rotate-12 {
  --tw-rotate: 12deg !important;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y)) !important;
}

.transform {
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y)) !important;
}

.grid-cols-1 {
  grid-template-columns: repeat(1, minmax(0, 1fr)) !important;
}

.flex-col {
  flex-direction: column !important;
}

.items-end {
  align-items: flex-end !important;
}

.items-center {
  align-items: center !important;
}

.items-stretch {
  align-items: stretch !important;
}

.justify-center {
  justify-content: center !important;
}

.justify-between {
  justify-content: space-between !important;
}

.space-x-14 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-x-reverse: 0 !important;
  margin-right: calc(3.5rem * var(--tw-space-x-reverse)) !important;
  margin-left: calc(3.5rem * calc(1 - var(--tw-space-x-reverse))) !important;
}

.space-x-6 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-x-reverse: 0 !important;
  margin-right: calc(1.5rem * var(--tw-space-x-reverse)) !important;
  margin-left: calc(1.5rem * calc(1 - var(--tw-space-x-reverse))) !important;
}

.space-x-5 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-x-reverse: 0 !important;
  margin-right: calc(1.25rem * var(--tw-space-x-reverse)) !important;
  margin-left: calc(1.25rem * calc(1 - var(--tw-space-x-reverse))) !important;
}

.space-y-7 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-y-reverse: 0 !important;
  margin-top: calc(1.75rem * calc(1 - var(--tw-space-y-reverse))) !important;
  margin-bottom: calc(1.75rem * var(--tw-space-y-reverse)) !important;
}

.space-x-12 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-x-reverse: 0 !important;
  margin-right: calc(3rem * var(--tw-space-x-reverse)) !important;
  margin-left: calc(3rem * calc(1 - var(--tw-space-x-reverse))) !important;
}

.divide-x > :not([hidden]) ~ :not([hidden]) {
  --tw-divide-x-reverse: 0 !important;
  border-right-width: calc(1px * var(--tw-divide-x-reverse)) !important;
  border-left-width: calc(1px * calc(1 - var(--tw-divide-x-reverse))) !important;
}

.divide-\[\#FFFFFF33\] > :not([hidden]) ~ :not([hidden]) {
  border-color: #fff3 !important;
}

.overflow-hidden {
  overflow: hidden !important;
}

.rounded-\[2\.75rem\] {
  border-radius: 2.75rem !important;
}

.rounded-sm {
  border-radius: .125rem !important;
}

.bg-orange {
  --tw-bg-opacity: 1 !important;
  background-color: rgb(245 60 49 / var(--tw-bg-opacity)) !important;
}

.bg-black {
  --tw-bg-opacity: 1 !important;
  background-color: rgb(0 0 0 / var(--tw-bg-opacity)) !important;
}

.bg-white {
  --tw-bg-opacity: 1 !important;
  background-color: rgb(255 255 255 / var(--tw-bg-opacity)) !important;
}

.px-11 {
  padding-left: 2.75rem !important;
  padding-right: 2.75rem !important;
}

.py-5 {
  padding-top: 1.25rem !important;
  padding-bottom: 1.25rem !important;
}

.py-7 {
  padding-top: 1.75rem !important;
  padding-bottom: 1.75rem !important;
}

.px-20 {
  padding-left: 5rem !important;
  padding-right: 5rem !important;
}

.py-40 {
  padding-top: 10rem !important;
  padding-bottom: 10rem !important;
}

.py-4 {
  padding-top: 1rem !important;
  padding-bottom: 1rem !important;
}

.py-32 {
  padding-top: 8rem !important;
  padding-bottom: 8rem !important;
}

.pt-64 {
  padding-top: 16rem !important;
}

.pb-4 {
  padding-bottom: 1rem !important;
}

.pt-6 {
  padding-top: 1.5rem !important;
}

.pb-14 {
  padding-bottom: 3.5rem !important;
}

.pt-60 {
  padding-top: 15rem !important;
}

.pl-4 {
  padding-left: 1rem !important;
}

.pr-14 {
  padding-right: 3.5rem !important;
}

.text-left {
  text-align: left !important;
}

.font-integralCF {
  font-family: Integral CF, ui-sans-serif, system-ui !important;
}

.font-generalSans {
  font-family: General Sans, ui-sans-serif, system-ui !important;
}

.font-loRes {
  font-family: Lo Res, ui-monospace !important;
}

.text-4xl {
  font-size: 2.25rem !important;
  line-height: 2.5rem !important;
}

.text-\[2\.5rem\] {
  font-size: 2.5rem !important;
}

.text-3xl {
  font-size: 1.875rem !important;
  line-height: 2.25rem !important;
}

.text-sm {
  font-size: .875rem !important;
  line-height: 1.25rem !important;
}

.text-\[1\.3125rem\] {
  font-size: 1.3125rem !important;
}

.text-8xl {
  font-size: 6rem !important;
  line-height: 1 !important;
}

.text-\[5rem\] {
  font-size: 5rem !important;
}

.font-semibold {
  font-weight: 600 !important;
}

.font-medium {
  font-weight: 500 !important;
}

.font-bold {
  font-weight: 700 !important;
}

.uppercase {
  text-transform: uppercase !important;
}

.text-orange {
  --tw-text-opacity: 1 !important;
  color: rgb(245 60 49 / var(--tw-text-opacity)) !important;
}

.text-blue {
  --tw-text-opacity: 1 !important;
  color: rgb(79 96 255 / var(--tw-text-opacity)) !important;
}

.text-black {
  --tw-text-opacity: 1 !important;
  color: rgb(0 0 0 / var(--tw-text-opacity)) !important;
}

.text-white {
  --tw-text-opacity: 1 !important;
  color: rgb(255 255 255 / var(--tw-text-opacity)) !important;
}

.underline {
  text-decoration-line: underline !important;
}

.opacity-50 {
  opacity: .5 !important;
}

.blur {
  --tw-blur: blur(8px) !important;
  filter: var(--tw-blur) var(--tw-brightness) var(--tw-contrast) var(--tw-grayscale) var(--tw-hue-rotate) var(--tw-invert) var(--tw-saturate) var(--tw-sepia) var(--tw-drop-shadow) !important;
}

.transition-transform {
  transition-property: transform !important;
  transition-duration: .15s !important;
  transition-timing-function: cubic-bezier(.4, 0, .2, 1) !important;
}

.duration-100 {
  transition-duration: .1s !important;
}

.duration-200 {
  transition-duration: .2s !important;
}

.ease-in {
  transition-timing-function: cubic-bezier(.4, 0, 1, 1) !important;
}

.ease-in-out {
  transition-timing-function: cubic-bezier(.4, 0, .2, 1) !important;
}

@font-face {
  font-family: Lo Res;
  font-weight: 400;
  src: url("LoRes-Regular.bb39f461.ttf") format("truetype");
  font-display: swap;
}

@font-face {
  font-family: Aileron;
  font-weight: 400;
  src: url("Aileron-Regular.08092412.otf") format("opentype");
  font-display: swap;
}

@font-face {
  font-family: Aileron;
  font-weight: 700;
  src: url("Aileron-Bold.b0bc4989.otf") format("opentype");
  font-display: swap;
}

@font-face {
  font-family: General Sans;
  font-weight: 500;
  src: url("GeneralSans-Medium.bff293e7.otf") format("opentype");
  font-display: swap;
}

@font-face {
  font-family: General Sans;
  font-weight: 600;
  src: url("GeneralSans-Semibold.cf6be9d0.otf") format("opentype");
  font-display: swap;
}

@font-face {
  font-family: Integral CF;
  font-weight: 600;
  src: url("IntegralCF-SemiBold.dc9ef4bc.otf") format("opentype");
  font-display: swap;
}

@font-face {
  font-family: Thunder;
  font-weight: 700;
  src: url("Thunder-Bold.5f485fd2.otf") format("opentype");
  font-display: swap;
}

body {
  color: #fff;
  background-color: #000;
  font-family: Aileron;
}

h1, h2, h3, h4, h5, h6 {
  font-family: Thunder;
}

.hover\:scale-150:hover {
  --tw-scale-x: 1.5 !important;
  --tw-scale-y: 1.5 !important;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y)) !important;
}

@media (min-width: 1024px) {
  .lg\:hidden {
    display: none !important;
  }

  .lg\:h-\[88vh\] {
    height: 88vh !important;
  }

  .lg\:w-11\/12 {
    width: 91.6667% !important;
  }

  .lg\:grid-cols-2 {
    grid-template-columns: repeat(2, minmax(0, 1fr)) !important;
  }

  .lg\:py-8 {
    padding-top: 2rem !important;
    padding-bottom: 2rem !important;
  }

  .lg\:px-8 {
    padding-left: 2rem !important;
    padding-right: 2rem !important;
  }

  .lg\:text-\[1\.6875rem\] {
    font-size: 1.6875rem !important;
  }

  .lg\:text-9xl {
    font-size: 8rem !important;
    line-height: 1 !important;
  }

  .lg\:text-xl {
    font-size: 1.25rem !important;
    line-height: 1.75rem !important;
  }

  .lg\:text-3xl {
    font-size: 1.875rem !important;
    line-height: 2.25rem !important;
  }
}

@media (max-width: 1023px) {
  .lgMax\:absolute {
    position: absolute !important;
  }

  .lgMax\:right-8 {
    right: 2rem !important;
  }

  .lgMax\:hidden {
    display: none !important;
  }

  .lgMax\:aspect-square {
    aspect-ratio: 1 / 1 !important;
  }
}

/*# sourceMappingURL=index.04e4eafd.css.map */
