@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face {
  font-family: 'Lo Res';
  font-weight: 400;
  src: url('../../public/fonts/LoRes-Regular.ttf') format('truetype');
  font-display: swap;
}

@font-face {
  font-family: 'Aileron';
  font-weight: 400;
  src: url('../../public/fonts/Aileron-Regular.otf') format('opentype');
  font-display: swap;
}

@font-face {
  font-family: 'Aileron';
  font-weight: 700;
  src: url('../../public/fonts/Aileron-Bold.otf') format('opentype');
  font-display: swap;
}

@font-face {
  font-family: 'General Sans';
  font-weight: 500;
  src: url('../../public/fonts/GeneralSans-Medium.otf') format('opentype');
  font-display: swap;
}

@font-face {
  font-family: 'General Sans';
  font-weight: 600;
  src: url('../../public/fonts/GeneralSans-Semibold.otf') format('opentype');
  font-display: swap;
}

@font-face {
  font-family: 'Integral CF';
  font-weight: 600;
  src: url('../../public/fonts/IntegralCF-SemiBold.otf') format('opentype');
  font-display: swap;
}

@font-face {
  font-family: 'Thunder';
  font-weight: 700;
  src: url('../../public/fonts/Thunder-Bold.otf') format('opentype');
  font-display: swap;
}

@layer base {
  .container {
    @apply mx-auto smMax:px-5 px-8 w-full;
    max-width: 68rem;
  }
}

body {
  font-family: Aileron;
  color: #fff;
  background-color: #000;
}

h1, h2, h3, h4, h5, h6 {
  font-family: Thunder;
}
